<template>
  <b-img
    :src="appLogoImage"
    alt="Biomedical Pharma"
    style="width: 40px; margin-top: -5px;"
  />
</template>

<script>
import { BImg } from 'bootstrap-vue'
import { $themeConfig } from '@themeConfig'

export default {
  components: {
    BImg,
  },
  setup() {
    // App Name
    const { appLogoImage } = $themeConfig.app

    return {
      // App Name
      appLogoImage,
    }
  },
}
</script>
